/*--
    - Custom Row
------------------------------------------*/
.row-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.row-5>[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}
.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row-10>[class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}
.row-30 {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-30>[class*="col"] {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-30>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-30>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-30>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-30>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-35 {
  margin-left: -35px;
  margin-right: -35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-35>[class*="col"] {
  padding-left: 35px;
  padding-right: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-40 {
  margin-left: -40px;
  margin-right: -40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-40>[class*="col"] {
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-50 {
  margin-left: -50px;
  margin-right: -50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-50>[class*="col"] {
  padding-left: 50px;
  padding-right: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50>[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-0 {
  margin-top: 0px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-150 {
  margin-top: 150px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-60 {
    margin-top: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-100 {
    margin-top: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-45 {
    margin-top: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-50 {
    margin-top: 50px !important;
  }
}
/*-- Margin Bottom --*/
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-30 {
    margin-bottom: 30px !important;
  }
}
/*-- Margin Left --*/
.ml-0 {
  margin-left: 0px !important;
}
/*-- Margin Right --*/
.mr-20 {
  margin-right: 20px !important;
}
.mr-130 {
  margin-right: 130px !important;
}
/*-- Padding Top --*/
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-55 {
  padding-top: 55px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-65 {
  padding-top: 65px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-75 {
  padding-top: 75px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-85 {
  padding-top: 85px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-95 {
  padding-top: 95px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-75 {
    padding-top: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-80 {
    padding-top: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-160 {
    padding-top: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-50 {
    padding-top: 50px !important;
  }
}
/*-- Padding Bottom --*/
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-55 {
  padding-bottom: 55px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-65 {
  padding-bottom: 65px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-85 {
  padding-bottom: 85px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-95 {
  padding-bottom: 95px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.pb-115 {
  padding-bottom: 115px !important;
}
.pb-120 {
  padding-bottom: 120px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-95 {
    padding-bottom: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-85 {
    padding-bottom: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-55 {
    padding-bottom: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-45 {
    padding-bottom: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-70 {
    padding-bottom: 70px !important;
  }
}
/*-- Padding Left --*/
.pl-0 {
  padding-left: 0px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
.pl-140 {
  padding-left: 140px !important;
}
.pl-150 {
  padding-left: 150px !important;
}
.pl-160 {
  padding-left: 160px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-15 {
    padding-left: 15px !important;
  }
  .pl-lg-30 {
    padding-left: 30px !important;
  }
  .pl-lg-40 {
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-10 {
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-15 {
    padding-left: 15px !important;
  }
}
/*-- Padding Right --*/
.pr-0 {
  padding-right: 0px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
.pr-140 {
  padding-right: 140px !important;
}
.pr-150 {
  padding-right: 150px !important;
}
.pr-160 {
  padding-right: 160px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-15 {
    padding-right: 15px !important;
  }
  .pr-lg-30 {
    padding-right: 30px !important;
  }
  .pr-lg-40 {
    padding-right: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-15 {
    padding-right: 15px !important;
  }
  .pr-xs-10 {
    padding-right: 10px !important;
  }
}
